.sidebar {
  position: fixed;
  top: 14%;
  bottom: 0;
  left: 0;
  padding: 58px 0 0;

  width: 300px;
  height: 100%;
  box-shadow: 0px 3px 54px #bfddff8a;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
.zone {
  color: #b3b4b4;
}
.refundBlock {
  /* box-shadow: 0px 3px 54px #bfddff8a; */
  padding: 30px;
  /* height: 100vh; */
  /* max-width: max-content !important; */
}
.refundback {
  font-family: "Helvetica";
  padding: 30px;
  margin-top: 100px;
  margin-left: 350px;
  margin-right: 50px;
  margin-bottom: 100px;
  min-height: auto;
  box-shadow: 0px 3px 54px #bfddff8a;
}
.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}
.menubar {
  padding-top: 5rem;
}
.menu-text {
  text-decoration: none;
  padding: 15px;
}

a.active {
  background-color: #bfddff8a;
  color: blue !important;
}
