html,
* {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.panAndAdhaar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
}

.center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.header {
  position: fixed;
  background-color: white;
  padding: 1.25rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: max-content;
  z-index: 200;
  box-shadow: 0px 3px 14px var(--aqua-1);
}

.memberfooter {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: whitesmoke;
  width: 100%;
  height: 10%;
}

.tablehead {
  width: 10%;
}

.disabled {
  cursor: not-allowed;
  color: rgb(163, 152, 152) !important;
  background-color: rgb(229, 229, 229) !important;
  border-color: rgb(163, 152, 152) !important;
}

.disable {
  cursor: not-allowed;
}

.disabled:hover {
  color: grey;
}
.disabled-filled {
  cursor: not-allowed;
  background-color: rgb(122, 115, 115) !important;
}
.disabled-filled:hover {
  color: rgb(211, 197, 197);
}
.radio {
  display: flex;
  padding: 0.5rem;
}
.radioFullPage {
  display: flex;
  justify-content: center;
  padding-right: 20rem;
  /* padding-left: 8rem; */
  padding-top: 1rem;
  /* padding-bottom: 1rem; */
}
.inputs {
  display: flex;
  padding: 0.5rem;
  gap: 1rem;
}
.inputsFullpage {
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
  gap: 1rem;
  padding-top: 1rem;
}
.uploadButton {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
}
/* .box {
  width: 150px;
  height: 150px;
  background-color: #fff;
  border-radius: 10px;
} */
.fullPageAlignment {
  padding: 30px;
  margin-top: 100px;
  margin-bottom: 100px;
  min-height: auto;
  /* box-shadow: 0px 3px 54px #bfddff8a; */
}
.alignText {
  margin: 2rem;
  text-align: left;
}
.blink_me {
  animation: blinker 1.5s linear infinite !important;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}
.edl-logo {
  height: 50px;
  width: 100px;
}
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed,
.darkAqua {
  color: var(--aqua-9) !important;
}

.aqua {
  color: var(--aqua-1) !important;
}
.btn-switch {
  width: 150px;
  height: 48px;
  background: var(--aqua-9);
  border-radius: 25px;
  opacity: 1;
  color: white;
  font-weight: bold;
}

.btn-switch:hover {
  color: white;
  background-color: rgb(0, 49, 55);
}
.err {
  color: black !important;
}
.MuiAlert-filledError {
  background-color: var(--coral-4) !important;
  border-radius: 25px !important;
}
.MuiAlert-filledSuccess {
  background-color: var(--aqua-9) !important;
  border-radius: 25px !important;
}

.btn-switch-completion {
  width: 200px;
  height: 65px;
  background: var(--aqua-9);
  border-radius: 25px;
  opacity: 1;
  color: white;
  font-weight: bold;
}

.btn-switch-completion:hover {
  color: white;
  background-color: rgb(0, 49, 55);
}

.input {
  /* border: 0; */
  padding: 8px 0;
  color: var(--aqua-9) !important;
  background-color: var(--aqua-2) !important;
  border-radius: 50px !important;
  box-sizing: border-box;
}
.form-control {
  padding: 8px;
}
.form-control:focus {
  border: 1px solid var(--aqua-9);
}
.inputErr {
  border: 1px solid red;
}
.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: var(--aqua-9) !important;
}
.MuiRadio-root.Mui-checked {
  color: var(--aqua-7) !important;
}
